import React from "react";
//Global Style
import GlobalStyle from "./components/GlobalStyle";
import Nav from "./components/Nav";
import {Route, Switch, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {createTheme, useMediaQuery} from "@material-ui/core";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import {ThemeProvider} from '@material-ui/styles';
import SearchDetail from "./pages/SearchDetail";
import Footer from "./components/Footer";

export const UserContext = React.createContext(null);

function App() {
    const location = useLocation();
    const matches = useMediaQuery('(max-width:800px)');
    const [user, setUser] = React.useState(null);
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00d432',
            },
            secondary: {
                main: '#222831',
            },
        },
    });

    const changeUser = (newUser) => {
        setUser(newUser);
    }

    return (
        <div className="App">
            <GlobalStyle/>

            <ThemeProvider theme={theme}>
                <UserContext.Provider value={{user: user, changeUser}}>
                    <Nav/>
                    <AnimatePresence exitBeforeEnter>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/" exact>
                                <Blogs/>
                            </Route>
                            <Route path="/post/:postid">
                                <BlogDetail/>
                            </Route>
                            <Route path="/search/:terms">
                                <SearchDetail/>
                            </Route>
                        </Switch>
                    </AnimatePresence>
                    <Footer />
                </UserContext.Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
