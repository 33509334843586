import React from "react";
import {Box} from "@material-ui/core";
import BlogPreview from "./BlogPreview";
import Block from "./Block";

function PostOverview({postList, fetching, errorBeschreibung, errorTitle}) {
    const results = (postList.items != null || postList.items !== undefined) && postList.items.length > 0;

    if (results) {
        return (
            <>
                <Box display={"grid"} gridGap={"50px"} gridAutoFlow={"dense"}
                     gridTemplateColumns={"repeat(auto-fill, minmax(0px, 365px))"}
                     justifyContent={"center"}>
                    {fetching === false && (postList.items.map((item) => {
                            return (
                                <BlogPreview img={item.images[0].url} title={item.title}
                                             description={item.content} postId={item.id}/>
                            )
                        })
                    )
                    }
                </Box>
            </>
        )
    } else {
        return (
            <>
                {fetching === true ? (
                        <>
                            <h1 style={{textAlign: "center", marginBottom: "150px"}}>Wird geladen...</h1>
                        </>
                    ) :
                    <>
                        <Block blockWidth={"75%"} title={errorTitle}
                               desc={errorBeschreibung}
                               renderTitleAndDesc={true}/>
                    </>
                }
            </>
        )
    }
}

export default PostOverview