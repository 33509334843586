import React, {useEffect} from "react";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import {useParams} from "react-router-dom";
import {Box, Button, Fade, Popper, Snackbar, useMediaQuery} from "@material-ui/core";
import styled from "styled-components";
import ScrollTop from "../components/ScrollTop";
import ContentComp from "../components/ContentComp";
import BlogPreview from "../components/BlogPreview";
import {makeStyles} from "@material-ui/core/styles";
import ShareIcon from '@material-ui/icons/Share';
import PostOverview from "../components/PostOverview";
import {Alert} from "@material-ui/lab";
import useGAEventTracker from "../components/useGAEventTracker";

const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid var(--main-accent-color)',
        borderRadius: "5px",
        padding: "15px 25px",
        backgroundColor: theme.palette.background.paper,
    },
}));

function BlogDetail() {
    let {postid} = useParams();
    const GAEventsTracker = useGAEventTracker("Post");
    const [postData, setPostDate] = React.useState(null);
    const [firstPosts, setFirstPosts] = React.useState(null);
    const [fetching, setFetchingTo] = React.useState(true);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const matches800 = useMediaQuery('(max-width:800px)');
    const matches1200 = useMediaQuery('(max-width:1200px)');

    useEffect(() => {
        fetch(`https://www.googleapis.com/blogger/v3/blogs/8977832423069781981/posts/${postid}?key=AIzaSyBACNXSBx9V2G-VTo8ALcehr5MqlQMuvmE&fetchImages=true`).then(res => res.json()).then(json => setPostDate(json)).then(() => fetch("https://www.googleapis.com/blogger/v3/blogs/8977832423069781981/posts?key=AIzaSyBACNXSBx9V2G-VTo8ALcehr5MqlQMuvmE&fetchImages=true&maxResults=6").then(res => res.json()).then(json => setFirstPosts(json)).then(() => setFetchingTo(false)));
    }, [fetching]);

    const handleClick = (event) => {
        GAEventsTracker("Share: "+postData.title)
        setAnchorEl(anchorEl ? null : event.currentTarget);
        navigator.clipboard.writeText(window.location.href);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                {fetching &&
                    <p style={{color: "white"}}>Lade Post...</p>
                }
                {fetching === false &&
                <>
                    <Box height={"80vh"} width={"100vw"}>
                        <IMG>
                            <img src={postData.images[0].url} alt="img"/>
                        </IMG>

                        <Content>
                            <div className={"flex-middle"}>
                                <h1>{postData.title}</h1>
                            </div>
                        </Content>
                    </Box>
                    <Box margin={!matches1200 ? "0 350px" : (!matches800 ? "0 150px" : "0 0px")} height={"100%"}>
                        <ContentStyle>

                            <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}
                                 paddingTop={"5%"}>
                                <Button variant={"contained"} size={"large"} color={"secondary"} onClick={handleClick}>
                                    Teilen <ShareIcon style={{marginLeft: "15%"}}/>
                                </Button>
                                <Snackbar
                                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={anchorEl}
                                    autoHideDuration={6000}
                                    onClose={() => setAnchorEl(false)}
                                >
                                    <Alert onClose={() => setAnchorEl(false)} severity="success">
                                        <p style={{color: "black"}}>Kopiert!</p>
                                    </Alert>
                                </Snackbar>
                            </Box>

                            <ContentComp postdata={postData} url={postData.images[0].url}
                                         padding={!matches800 ? "50px" : "20px"}/>

                            <h2 style={{color: "white", textAlign: "center", marginTop: "5%"}}>Aktuelles</h2>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={"2%"}
                                 marginBottom={"5%"}>
                                <Line transition={{duration: 0.75}} initial={{width: "0%"}} animate={{width: "60%"}}/>
                            </Box>

                            <Box paddingBottom={"10%"}>
                                <PostOverview postList={firstPosts} fetching={fetching}
                                              errorTitle={"Keine weiteren Post vorhanden :("}
                                              errorBeschreibung={"Schicke mir jetzt dein Post-Thema ein. Das tust du, indem du oben auf 'Kontakt' klickst. Danke dir!"}/>
                            </Box>
                        </ContentStyle>
                    </Box>
                </>
                }
            </motion.div>
            <ScrollTop/>
        </>
    )
}

const Line = styled(motion.div)`
  height: 0.4rem;
  background: var(--main-accent-color);
  width: 0%;
`;

const ContentStyle = styled.div`
background-color: var(--main-bg-color-sec);
border-radius: 10px 10px 0 0;

span {
    color: white;
    font-weight: unset;
}

img {
    height: 100%;
    width: 100%;
    text-align: center;
}

a {
    text-decoration: unset;
    font-size: 1.4rem;
    color: var(--main-accent-color) !important;
    margin: 0 !important;
    
    span {
        color: var(--main-accent-color) !important;
    }
}

ul {
    list-style: none;
}

ul li::before {
    content: "\\2022";
    color: white; 
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

li {
    margin-left: 5%;
    margin-top: 0.5%;
    
}

blockquote {
    padding: 30px 50px;
    background-color: var(--main-bg-color);
    border-radius: 10px;
}

hr {
  height: 0.4rem;
  background-color: var(--main-accent-color);
  border: none;
  margin-top: 3%;
  margin-bottom: 3%;
}

p {
    padding: 0px;
}
`

const IMG = styled.div`
height: 100%;
width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(5px);
    position: fixed;
    z-index: -5;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--other-color);
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;

  .flex-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.7);
    padding: 20px 50px;
    width: 50%;
    @media (max-width: 1200px) {
      width: unset !important;
    }
    @media (max-width: 400px) {
      text-align: center;
    }
  }

  h1 {
    color: white;
    text-align: center;
  }

  h1 {
    font-size: 50px;
    width: 100%;
    @media (max-width: 650px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 25px;
      width: 100%;
    }
  }
`;

export default BlogDetail