import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {Box, Button, CircularProgress, Fade, Modal, Snackbar, TextField, useMediaQuery} from "@material-ui/core";
import {Auth} from 'aws-amplify';
import {Alert} from "@material-ui/lab";
import {useHistory} from 'react-router-dom';

const TextFiel = styled(TextField)({
    color: "white !important",

    '& label': {
        color: 'white',
    },

    '& .MuiInputBase-input': {
        color: 'white',
    },

    '& label.Mui-focused': {
        color: 'var(--main-accent-color)',
    },

    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--main-accent-color)',
    },

    '& input:focus': {
        color: 'white !important',
    },

    '& textarea:focus': {
        color: 'white !important',
    },
});

function LoginModal({open, setOpen, setUser}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [loggingIn, setLoggingIn] = useState(false);
    const [error, setError] = useState("");
    const [errorShow, setErrorShow] = useState(false);
    const [passwort, setPasswort] = useState("");
    const [needNewPassword, setNeedNewPassword] = useState(false);
    const [newPasswort, setNewPasswort] = useState("");
    const matches600 = useMediaQuery('(max-width:600px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !matches600 ? "50%" : "90%",
        bgcolor: 'var(--main-bg-color-sec)',
        outline: "none",
        border: '2px solid #000',
        boxShadow: 24,
        color: "white",
    };

    const handleChangePassword = async (e) => {
        setLoggingIn(true)
        if(!newPasswort) {
            setError("Bitte befülle alle Felder!")
            setErrorShow(true)
            setLoggingIn(false);
            return;
        }
        const user = await Auth.signIn(email, passwort);
        Auth.completeNewPassword(
            user,
            newPasswort,
        ).then(async user => {
            setEmail(email);
            setPasswort("");
            setNeedNewPassword(false);
            setLoggingIn(false);
        }).catch(e => {
            console.log(e);
        });
    }

    const handleLogin = async (e) => {
        setLoggingIn(true);
        if (!email || !passwort) {
            setError("Bitte befülle alle Felder!")
            setErrorShow(true)
            setLoggingIn(false);
            return;
        }
        try {
            const user = await Auth.signIn(email, passwort);
            switch (user.challengeName) {
                case "NEW_PASSWORD_REQUIRED":
                case "FORCE_CHANGE_PASSWORD":
                    setLoggingIn(false);
                    setNeedNewPassword(true);
                    return;
                default:
                    break;
            }
            if (!needNewPassword) {
                setUser(user);
                setLoggingIn(false);
                setOpen(false);
            }
        } catch (err) {
            setError("Bitte überprüfe deine Eingaben!")
            setErrorShow(true)
            console.log("error signing in", err);
            setLoggingIn(false);
            return;
        }
        handleClose();
    }

    useEffect(() => {
        async function fetchMyAPI() {
            await Auth.currentAuthenticatedUser().then((user) => {
                setUser(user);
            }).catch((err) => {
                console.log("user is not authentificated", err);
            })
        }

        fetchMyAPI()
    }, [])

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}
                             flexDirection={"column"}>
                            <h1 style={{marginTop: "3%"}}>Login</h1>
                            <p style={{textAlign: "center", padding: matches600 && "20px"}}>Bitte überprüfe vorher deine
                                E-Mails, solltest du bereits etwas gekauft haben.</p>
                            <Box width={!matches600 ? "50%" : "75%"}>
                                <TextFiel type={"text"} margin={"dense"} value={email}
                                          onChange={(e) => setEmail(e.target.value)}
                                          fullWidth label="E-Mail" variant="filled"/>
                                <TextFiel type={"password"} margin={"dense"} value={passwort}
                                          onChange={(e) => setPasswort(e.target.value)}
                                          fullWidth label={!needNewPassword ? "Passwort" : "Temporäres Passwort"}
                                          variant="filled"/>
                                {needNewPassword &&
                                <TextFiel type={"password"} margin={"dense"} value={newPasswort}
                                          onChange={(e) => setNewPasswort(e.target.value)}
                                          fullWidth label="Neues Passwort" variant="filled"/>
                                }
                            </Box>
                        </Box>
                        <Snackbar
                            anchorOrigin={{vertical: "top", horizontal: "center"}}
                            open={errorShow}
                            autoHideDuration={6000}
                            onClose={() => setErrorShow(false)}
                        >
                            <Alert onClose={() => setErrorShow(false)} severity="error">
                                {error}
                            </Alert>
                        </Snackbar>
                        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} padding={"15px"}
                             position={"relative"}>
                            {!needNewPassword ?
                                <Button style={{marginLeft: "3%", position: "relative"}} onClick={handleLogin}
                                        disabled={loggingIn}>
                                    Anmelden
                                </Button>
                                :
                                <Button style={{marginLeft: "3%", position: "relative"}} onClick={handleChangePassword}>
                                    Speichern
                                </Button>
                            }
                            {loggingIn && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "var(--main-accent-color)",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default LoginModal