import React from "react";

function ReadMore({children}) {
    let text = children;
    text = text.replace(/<[^>]+>/g, '');
    const isReadMore = text.length > 200;

    return (
        <>

            {isReadMore
                ?
                <p style={{color: "#ccc", padding: "3rem 0rem"}} dangerouslySetInnerHTML={{__html: text.slice(0, 200) + "..."}}/>
                :
                <p style={{color: "#ccc", padding: "3rem 0rem"}} dangerouslySetInnerHTML={{__html: text}}/>
            }
        </>
    )
}

export default ReadMore