import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import useGAEventTracker from "./useGAEventTracker";

function Footer() {
    const GAEventsTracker = useGAEventTracker("Footer");

    const handleClick = (clicked) => {
        GAEventsTracker(clicked);
    }

    return (
        <>
            <Styling>
                <div className="footer-main-div">
                    <div className="footer-socail-icons">
                        <ul>
                            <li onClick={() => handleClick("Facebook")}>
                                <a href="https://www.facebook.com/pringe.de" target="_blank">
                                    <FacebookIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("Instagram")}>
                                <a href="https://instagram.com/pringe.de" target="_blank">
                                    <InstagramIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("YouTube")}>
                                <a href="https://www.youtube.com/channel/UC2ChFU0pjo_E60LB-uXzJjA" target="_blank">
                                    <YouTubeIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("TikTok")}>
                                <a href="https://tiktok.com/@pringe.de" target="_blank">
                                    <VideoLabelIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("Whatsapp")}>
                                <a href="https://api.whatsapp.com/send?phone=491606366420" target="_blank">
                                    <WhatsAppIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("E-Mail")}>
                                <a href="mailto:info@pringe.de" target="_blank">
                                    <EmailIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("Twitter")}>
                                <a href="https://twitter.com/pringe_de" target="_blank">
                                    <TwitterIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                            <li onClick={() => handleClick("LinkedIn")}>
                                <a href="https://www.linkedin.com/company/pringe/?viewAsMember=true" target="_blank">
                                    <LinkedInIcon className={"icon"} fontSize={"large"}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-menu-one">
                        <ul>
                            <li onClick={() => handleClick("Impressum")}>
                                <a href={"https://pringe.de/impressum"} target={"_blank"}>
                                    Impressum
                                </a>
                            </li>
                            <li onClick={() => handleClick("Datenschutz")}>
                                <a href={"https://pringe.de/datenschutz"} target={"_blank"}>
                                    Datenschutz
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Styling>
        </>
    )
}

const Styling = styled(motion.footer)`
background: var(--main-bg-color-sec);
margin-top: auto;
border-top: 2px solid var(--main-accent-color);

.footer-main-div {
  width: 100%;
  height: auto;
  margin: auto;
  background: var(--main-bg-color-sec);
  padding: 20px 0px;
}

.footer-socail-icons {
  width: 100%;
  height: auto;
  margin: auto;

  ul {
    margin: 0px;
    padding: 0px;
    text-align: center;

    li {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 0px 10px;
      border-radius: 100%;
      background: var(--main-bg-color);
      transition: 0.3s;
      
      &:hover {
        background: var(--main-accent-color);
      }

      a {
        color: #fff;
        font-size: 25px;
        display: block;
        height: 100%;

        .icon {
          height: 100%;
        }
      }
    }
  }
}

.footer-menu-one {
  width: 100%;
  height: auto;
  margin: auto;
  height: auto;
  margin-top: 20px;

  ul {
    margin: 0px;
    padding: 0px;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 15px;
      margin-bottom: 40px;

      a {
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        transition: 0.3s;

        &:hover {
          color: var(--main-accent-color);
        }
      }
    }
  }
}

.footer-bottom {
  text-align: center;
  background: var(--main-bg-color-sec);
  color: #fff;

  p {
    font-size: 20px;
    font-weight: 200;
    color: #fff;
  }
}
`

export default Footer