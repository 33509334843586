import React, {useContext} from "react";
import styled from "styled-components";
import {Box, Paper, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LoginModal from "./LoginModal";
import {UserContext} from "../App";
import useGAEventTracker from "./useGAEventTracker";
import axios from "axios";
import {loadStripe} from "@stripe/stripe-js";

const useStyles = makeStyles((theme) => ({
    steplabel: {
        color: "white !important",
    },
    completed: {
        color: "white",
    },
    inputField: {
        color: "white",

        '& label.Mui-focused': {
            color: 'var(--main-accent-color)',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: 'var(--main-accent-color)',
        },

        '& input:focus': {
            color: 'white !important',
        },

        '& textarea:focus': {
            color: 'white !important',
        },
    },

    paperroot: {
        transition: "0.3s",
        border: "1px solid transparent",

        '&:hover': {
            border: "1px solid var(--main-accent-color)",
        },
    }
}));

const ClickField = function ClickField({classes, func, icon, text}) {
    return (
        <>
            <Paper classes={{root: classes.paperroot}} elevation={15} variant={"elevation"} onClick={func}
                   style={{flex: "1 1 25%", margin: "15px", backgroundColor: "var(--main-bg-color)"}}>
                <Box display={"flex"} flexDirection={"column"} width={"100%"}
                     height={"100%"} justifyContent={"center"} alignItems={"center"} paddingTop={"10px"}>
                    <img src={icon} style={{width: "50px", flex: "100%"}} alt="icon"/>
                    <p style={{color: "white", textAlign: "center", flex: "100%", padding: "1.5rem 0rem"}}>
                        {text}
                    </p>
                </Box>
            </Paper>
        </>
    )
}

function ContentComp({postdata, url, padding}) {
    const classes = useStyles();
    const GAEventsTracker = useGAEventTracker("Post");
    const [open, setOpen] = React.useState(false);
    const {user, changeUser} = useContext(UserContext);
    const matches900 = useMediaQuery('(max-width:900px)');
    const Style = styled.div`
      h2 {
        font-weight: lighter;
        font-size: 3.5rem;
        margin: 30px 0;
        line-height: 50px;
      }

      h3 {
        margin-top: 3%;
        margin-bottom: 3%;
      }

      padding: ${padding};

      a[href="${url}"] {
        display: none;
      }
    `

    // test
    // const handleBuyClick = async e => {
    //     alert("Bitte überprüfe nach dem Kauf deine E-Mails.");
    //     GAEventsTracker("Buy");
    //
    //     axios.post('https://mmqkhkvbge.execute-api.eu-north-1.amazonaws.com/dev/create-checkout-session', [{
    //         price: 'price_1JiIRpK7gRZLSJmgwWLzDg6f',
    //         quantity: 1,
    //         tax_rates: ["txr_1KF1UuK7gRZLSJmgyBIDPgL8"],
    //     }]).then(response => {
    //         window.location.replace(response.data)
    //     }).catch(err => console.log("generate checkout session error", err));
    //     // const stripe = await loadStripe('pk_live_51I8W9ZK7gRZLSJmgKG59Zi5NrQZexlQvYuPG1fVvjARA9gbZluuVa6P3OePVn6MkCcboDrygfdFcQzC5o8CQBdME00KKZBzhs1');
    // }

    const handleBuyClick = async e => {
        GAEventsTracker("Buy");
        window.open("https://buy.stripe.com/3cs5ncfGlbU09Gg003", "_blank");
        // const stripe = await loadStripe('pk_live_51I8W9ZK7gRZLSJmgKG59Zi5NrQZexlQvYuPG1fVvjARA9gbZluuVa6P3OePVn6MkCcboDrygfdFcQzC5o8CQBdME00KKZBzhs1');
        // const {error} = await stripe.redirectToCheckout({
        //     lineItems: [{
        //         price: 'price_1JiIRpK7gRZLSJmgwWLzDg6f',
        //         quantity: 1
        //     }],
        //     mode: "subscription",
        //     successUrl: window.location.href,
        //     cancelUrl: window.location.href + '/cancel'
        // })
    }

    const keywordY = "&lt;paymentwall&gt;";
    const contentX = postdata.content;
    var extractedZ = contentX.split(keywordY).pop();

    return (
        <>
            <Style>
                <p dangerouslySetInnerHTML={{__html: postdata.content.replace("<a name=\'more\'></a>", "<hr/>").replace(keywordY, "")}}/>

                {/*<LoginModal setOpen={setOpen} open={open} setUser={changeUser}/>*/}

                {!user &&
                    <Box borderRadius={"4px"} padding={"10px"} style={{
                        border: "1px solid var(--main-accent-color)"
                    }}>
                        <h2 style={{textAlign: "center", color: "white", marginBottom: "0"}}>Möchtest du mich unterstützen?</h2>
                        {/*<p dangerouslySetInnerHTML={{__html: extractedZ.slice(0, 109)}}/>*/}
                        <Box display={"flex"} height={"250px"} justifyContent={"center"} alignItems={"center"}
                             flexDirection={matches900 && "column"}>
                            <Box height={"100%"} width={"100%"} textAlign={"center"} padding={!matches900 && "50px"}>
                                <ClickField classes={classes} text={"Für diesen Beitrag gerne!"}
                                            icon={"/img/moneyGrowth.svg"}
                                            func={handleBuyClick}/>
                            </Box>
                            {/*<Box height={"100%"} width={"100%"} textAlign={"center"} padding={!matches900 && "50px"}>*/}
                            {/*    <ClickField classes={classes} text={"Anmelden"} icon={"/img/login.svg"}*/}
                            {/*                func={() => {*/}
                            {/*                    GAEventsTracker("Open Login Modal")*/}
                            {/*                    setOpen(true)*/}
                            {/*                }}/>*/}
                            {/*</Box>*/}
                        </Box>
                    </Box>
                }
            </Style>
        </>
    )
}

export default ContentComp