import React, {Component} from 'react';
import {Box, IconButton, Input, InputAdornment, InputLabel, withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
    label: {
        color: "white !important",
        fontSize: "1.3rem",
    },
    inputField: {
        color: "white !important",
        fontSize: "1.5rem",

        '& label.Mui-focused': {
            color: 'var(--main-accent-color)',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: 'var(--main-accent-color)',
        },

        '& input:focus': {
            color: 'white !important',
        },

        '& textarea:focus': {
            color: 'white !important',
        },
    },
});

class Searchbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            search: this.props.value,
        }
    }

    render() {
        const handleChange = (e) => {
            this.setState({...this.state, search: e.target.value});
        }

        const {classes} = this.props;
        return (
            <>
                <Box marginBottom={"50px"}>
                    <InputLabel htmlFor="standard-adornment-password" classes={{root: classes.label}}>Suchen...</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        onKeyUp={handleChange}
                        classes={{root: classes.inputField}}
                        fullWidth
                        placeholder={this.state.search}
                        endAdornment={
                            <InputAdornment position="end" style={{color: "white"}}>
                                <Link to={"/search/" + this.state.search}>
                                    <IconButton
                                        aria-label="search with search terms"
                                    >
                                        <SearchIcon fontSize={"large"}/>
                                    </IconButton>
                                </Link>
                            </InputAdornment>
                        }
                    />
                </Box>
            </>
        );
    }
}

export default withStyles(styles, {withTheme: true})(Searchbar);