import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Link, useLocation, withRouter} from "react-router-dom";
import {motion} from "framer-motion";
import {Box, useMediaQuery} from "@material-ui/core";
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {makeStyles} from "@material-ui/core/styles";
import ReactGA from 'react-ga';
import useGAEventTracker from "./useGAEventTracker";
import { hotjar } from 'react-hotjar';

const useStyles = makeStyles((theme) => ({
    root: {
        color: "white",
        fontSize: "40px",
        marginRight: "15px",
    },
}));

const navigator = {
    hidden: {
        y: -100,
        height: "0px",
        transition: {
            duration: 0.75,
            ease: "easeOut",
        },
    },
    visible: {
        y: 0,
        height: "100%",
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delayChildren: 0.3,
            staggerChildren: 0.1
        },
    }
};

const naviItem = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut",
        },
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeOut",
        },
    }
}

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {debug: false})
hotjar.initialize(2666913,6);
const Nav = () => {
    const {pathname} = useLocation();
    const GAEventsTracker = useGAEventTracker("Nav");
    const [isOpen, setIsOpen] = useState(false);
    const matches = useMediaQuery('(max-width:600px)');
    const classes = useStyles();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        hotjar.stateChange(window.location.pathname + window.location.search);
    })

    const toggleNavi = () => {
        setIsOpen(!isOpen)
    }

    return (
        <StyledNav>
            <Box display={"flex"} width={matches && "100%"} justifyContent={!matches ? "center" : "space-between"}
                 alignItems={"center"}>
                <h1>
                    <Link id="logo" to={"/"}>
                        blog.Pringe.de
                    </Link>
                </h1>
                {matches &&
                <>
                    {isOpen
                        ?
                        <CloseOutlinedIcon classes={{root: classes.root}} onClick={toggleNavi}/>
                        :
                        <MenuOutlinedIcon classes={{root: classes.root}} onClick={toggleNavi}/>
                    }
                </>
                }
            </Box>
            <motion.ul variants={navigator} animate={(isOpen || !matches) ? "visible" : "hidden"} initial={"hidden"}
                       style={matches && {paddingTop: "0px"}}>
                <motion.li variants={naviItem}>
                    <Link to="/">Alle Posts</Link>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                        animate={{width: pathname === "/" ? "50%" : "0%"}}
                    />
                </motion.li>
                <motion.li variants={naviItem}>
                    <a href="https://pringe.de/aboutme" onClick={() => GAEventsTracker("Click: Über mich")}>Über mich</a>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                    />
                </motion.li>
                <motion.li variants={naviItem}>
                    <a href="https://pringe.de/contact" onClick={() => GAEventsTracker("Click: Kontakt")}>Kontakt</a>
                    <Line
                        transition={{duration: 0.75}}
                        initial={{width: "0%"}}
                    />
                </motion.li>
            </motion.ul>
        </StyledNav>
    );
};

const StyledNav = styled.nav`
  border-bottom: 2px solid var(--main-accent-color);
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  background: var(--main-bg-color-sec);
  position: sticky;
  top: 0;
  z-index: 10;
  a {
    color: white;
    text-decoration: none;
  }
  ul {
    display: flex;
    list-style: none;
  }
  #logo {
    font-size: 1.75rem;
    font-family: "Lobster", cursive;
    font-weight: lighter;
  }
  li {
    padding-left: 10rem;
    position: relative;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 2rem 1rem;
    #logo {
      display: inline-block;
      margin: 1rem;
    }
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }
  @media (max-width: 600px) {
    ul {
      flex-direction: column;
      padding: 2rem;
      padding-top: 1rem;
      padding-bottom: 0px;
      align-items: center;
      
      li {
        padding-bottom: 2rem;
      }
    }
  }
`;

const Line = styled(motion.div)`
  height: 0.3rem;
  background: var(--main-accent-color);
  width: 0%;
  position: absolute;
  bottom: -80%;
  left: 60%;
  @media (max-width: 1300px) {
    left: 0%;
  }
  
  @media (max-width: 600px) {
    bottom: 30%; 
  }
`;

export default withRouter(Nav);
