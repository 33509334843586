import React from "react";
import Block from "./Block";
import {Button, Snackbar, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import CustomCheckbox from "./CustomCheckbox";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    inputField: {
        '& input:focus': {
            color: 'white !important',
        },

        '& textarea:focus': {
            color: 'white !important',
        },
    },
}));

function NewsletterSignIn() {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const classes = useStyles();

    return (
        <>
            <Block blockWidth={"75%"} renderTitleAndDesc={false}>
                <div className="klaviyo-form-WF8Wue"></div>
                {/*<form onSubmit={handleSubmit}>*/}
                {/*    <TextField fullWidth label="Mein Name ist.." variant="filled" color={"primary"}*/}
                {/*               classes={{root: classes.inputField}} required/>*/}
                {/*    <TextField margin={"normal"} fullWidth label="Meine E-Mail Adresse lautet..." variant="filled"*/}
                {/*               color={"primary"} classes={{root: classes.inputField}} required/>*/}
                {/*    <CustomCheckbox required={true} defaultChecked={false}*/}
                {/*                    text={"Ja, ich will den Newsletter mit Infos zur Website, Tipps, laufenden Verbesserungen, Design Tipps, Ideen und Anregungen abonnieren (kostenfrei). Hinweise zu der von der Einwilligung mitumfassten Erfolgsmessung, dem Einsatz des Versanddienstleisters MailChimp, Protokollierung der Anmeldung und Ihren Widerrufsrechten erhalten Sie in unserer Datenschutzerklärung."}/>*/}
                {/*    <Button type={"submit"} variant="outlined" style={{marginTop: "5%"}} size={"large"} color="primary"*/}
                {/*            endIcon={<SendIcon/>}>*/}
                {/*        Verschicken*/}
                {/*    </Button>*/}
                {/*</form>*/}
            </Block>
            {/*<Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} autoHideDuration={6000}*/}
            {/*          onClose={handleClose}>*/}
            {/*    <Alert onClose={handleClose} severity="error">*/}
            {/*        Diese Funktion ist noch nicht verfügbar!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </>
    )
}

export default NewsletterSignIn