import React, {useEffect} from "react";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import {useParams} from "react-router-dom";
import ScrollTop from "../components/ScrollTop";
import BlogPreview from "../components/BlogPreview";
import {Box} from "@material-ui/core";
import Searchbar from "../components/Searchbar";
import Block from "../components/Block";
import useGAEventTracker from "../components/useGAEventTracker";

function SearchDetail() {
    let {terms} = useParams();
    const GAEventsTracker = useGAEventTracker("Search");
    const [searchResponse, setSearchResponse] = React.useState(null);
    const [fetching, setFetching] = React.useState(true);

    useEffect(() => {
        GAEventsTracker("Nach: " + terms);
        fetch(`https://www.googleapis.com/blogger/v3/blogs/8977832423069781981/posts/search?q=${terms}&key=AIzaSyBACNXSBx9V2G-VTo8ALcehr5MqlQMuvmE&fetchImages=true&orderBy=published`).then(res => res.json()).then(json => setSearchResponse(json)).then(() => setFetching(false));
    }, [])

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={"5%"} width={"100%"}>
                    <Searchbar value={terms}/>
                </Box>
                {fetching === false && ((searchResponse.items != null || searchResponse.items !== undefined) && searchResponse.items.length > 0 ? (
                            <Box display={"grid"} gridGap={"50px"}
                                 gridAutoFlow={"dense"}
                                 gridTemplateColumns={"repeat(auto-fill, minmax(0px, 365px))"}
                                 justifyContent={"center"}>
                                {searchResponse.items.map((item) => {
                                    return (
                                        <BlogPreview
                                            img={"/img/lupe.png"}
                                            title={item.title}
                                            description={item.content} postId={item.id}/>
                                    )
                                })}
                            </Box>
                        ) :
                        (
                            <Block blockWidth={"75%"} title={"Keine Suchergebnisse :("}
                                   desc={"Wenn du magst, kannst du mir eine Nachricht schreiben. Dadurch kann ich über dein Thema auch ein Post verfassen. Klicke dafür auf 'Kontakt'! Danke :)"} renderTitleAndDesc={true}/>
                        )
                )
                }
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default SearchDetail