import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import styled from "styled-components";
import {motion} from "framer-motion";

function Block(props) {
    const matches = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}
                 flexDirection={"column"} margin={"150px 0"}>
                <Box width={!matches ? props.blockWidth : "unset"} textAlign={"center"} color={"white"}
                     style={{backgroundColor: "var(--main-bg-color-sec)"}} borderRadius={"15px"}
                     padding={!matches ? "50px" : "25px"}>
                    {props.renderTitleAndDesc &&
                        <>
                    <h2>{props.title}</h2>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={"2%"}>
                        <Line transition={{duration: 0.75}} initial={{width: "0%"}} animate={{width: "60%"}}/>
                    </Box>
                    <p>{props.desc}</p>
                        </>
                    }
                    {props.children}
                </Box>
            </Box>
        </>
    )
}

const Line = styled(motion.div)`
  height: 0.4rem;
  background: var(--main-accent-color);
  width: 0%;
`;

export default Block