import React, {Component} from "react";
import {Box} from "@material-ui/core";
import styled from "styled-components";
import BlogPreview from "../components/BlogPreview";
import NewsletterSignIn from "../components/NewsletterSignIn";
import Block from "../components/Block";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import Searchbar from "../components/Searchbar";
import PostOverview from "../components/PostOverview";

class Blogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bloginfo: {},
            firstPosts: {},
            otherPosts: {},
            fetching: true,
            search: "",
        }
    }

    componentDidMount() {
        fetch("https://www.googleapis.com/blogger/v3/blogs/8977832423069781981?key=AIzaSyBACNXSBx9V2G-VTo8ALcehr5MqlQMuvmE").then(res => res.json()).then(json => json != null && (this.setState({bloginfo: json})));
        fetch("https://www.googleapis.com/blogger/v3/blogs/8977832423069781981/posts?key=AIzaSyBACNXSBx9V2G-VTo8ALcehr5MqlQMuvmE&fetchImages=true&maxResults=6").then(res => res.json()).then(json => json != null && (this.setState({firstPosts: json}))).then(() =>
            fetch(`https://www.googleapis.com/blogger/v3/blogs/8977832423069781981/posts?key=AIzaSyBACNXSBx9V2G-VTo8ALcehr5MqlQMuvmE&fetchImages=true&pageToken=${this.state.firstPosts.nextPageToken}`).then(res2 => res2.json()).then(json2 => json2 != null && (this.setState({otherPosts: json2})))).then(() => this.setState({fetching: false}));
    }

    render() {
        return (
            <>
                <Selection>
                    <motion.div
                        exit="exit"
                        variants={pageAnimation}
                        initial="hidden"
                        animate="show"
                    >
                        <Box margin={"0 25px"}>
                            <Block blockWidth={"50%"} title={this.state.bloginfo.name}
                                   desc={this.state.bloginfo.description} renderTitleAndDesc={true}/>
                        </Box>
                        <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                            <Box className={"widthFlex"} width={"75%"} color={"white"}>
                                <Searchbar value={"Website programmieren"}/>
                                <PostOverview fetching={this.state.fetching} postList={this.state.firstPosts} errorTitle={"Aktuell gibt es noch keine Posts :("} errorBeschreibung={"Wenn du magst, kannst du mir eine Nachricht, mit deinem Thema schreiben. Ich bin mir ziemlich sicher, dass es ich auch dein Post-Thema verfassen werde. Klicke dafür auf 'Kontakt'! Danke :)"}/>
                                <NewsletterSignIn/>
                                <PostOverview fetching={this.state.fetching} postList={this.state.otherPosts} errorTitle={"Aktuell gibt es noch nicht mehr als 6 Posts :("} errorBeschreibung={"Wenn du magst, kannst du mir eine Nachricht, mit deinem Thema schreiben. Ich bin mir ziemlich sicher, dass es ich auch dein Post-Thema verfassen werde. Klicke dafür auf 'Kontakt'! Danke :)"}/>
                            </Box>
                        </Box>
                    </motion.div>
                </Selection>
                <ScrollTop/>
            </>
        )
    }
}

const Selection = styled.div`
color: white;

@media (max-width: 600px) {
    .widthFlex {
        width: unset;
        margin: 0 25px;
    }
}

label, input, textarea {
    color: white;
    
    &:focus {
        color: black;
    }
}

.MuiInput-underline:after {
    border-bottom: 2px solid var(--main-accent-color);
}
`
export default Blogs;