import React from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from '@material-ui/core/styles';
import {motion} from "framer-motion";
import styled from "styled-components";
import {Box, Button, Paper} from "@material-ui/core";
import ReadMore from "./ReadMore";
import {useHistory, useLocation} from "react-router-dom";
import useGAEventTracker from "./useGAEventTracker";

const useStyles = makeStyles({
    root: {
        maxWidth: 365,
        backgroundColor: "var(--main-bg-color-sec)"
    },
});

function BlogPreview({title, description, img, postId}) {
    const classes = useStyles();
    let history = useHistory();
    const {pathname} = useLocation();
    const GAEventsTracker = useGAEventTracker("Blog PreView");

    const handlePostDetail = () => {
        if (pathname === "/post/" + postId)
            return;
        GAEventsTracker("Click: " + title, postId);
        history.push("/post/" + postId);
    }

    return (
        <>
            <BlogCard>
                <Paper elevation={20}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={handlePostDetail}>
                            <CardMedia
                                image={img}
                                style={{
                                    height: "250px",
                                }}
                            />
                        </CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant={"h4"} align={"center"} style={{color: "white"}}
                                        component="h2">
                                {title}
                            </Typography>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={"2%"}>
                                <Line transition={{duration: 0.75}} initial={{width: "0%"}} animate={{width: "100%"}}/>
                            </Box>
                            <Typography variant="h6" style={{padding: "0px"}} component="p">
                                <ReadMore>
                                    {description}
                                </ReadMore>
                            </Typography>
                            <Button variant={"contained"} size={"large"} color={"secondary"} onClick={handlePostDetail}>
                                Weiterlesen
                            </Button>
                        </CardContent>
                    </Card>
                </Paper>
            </BlogCard>
        </>
    )
}

const Line = styled(motion.div)`
  height: 0.2rem;
  background: var(--main-accent-color);
  width: 0%;
`;

const BlogCard = styled(motion.div)`
`;

export default BlogPreview